export const countries: { code: number; label: string }[] = [
  { code: 203, label: 'Česko (CZ)' },
  { code: 4, label: 'Afghánistán (AF)' },
  { code: 248, label: 'Alandy (AX)' },
  { code: 8, label: 'Albánie (AL)' },
  { code: 12, label: 'Alžírsko (DZ)' },
  { code: 16, label: 'Americká Samoa (AS)' },
  { code: 850, label: 'Americké Panenské ostrovy (VI)' },
  { code: 20, label: 'Andorra (AD)' },
  { code: 24, label: 'Angola (AO)' },
  { code: 660, label: 'Anguilla (AI)' },
  { code: 10, label: 'Antarktida (AQ)' },
  { code: 28, label: 'Antigua a Barbuda (AG)' },
  { code: 32, label: 'Argentina (AR)' },
  { code: 51, label: 'Arménie (AM)' },
  { code: 533, label: 'Aruba (AW)' },
  { code: 36, label: 'Austrálie (AU)' },
  { code: 31, label: 'Ázerbájdžán (AZ)' },
  { code: 44, label: 'Bahamy (BS)' },
  { code: 48, label: 'Bahrajn (BH)' },
  { code: 50, label: 'Bangladéš (BD)' },
  { code: 52, label: 'Barbados (BB)' },
  { code: 56, label: 'Belgie (BE)' },
  { code: 84, label: 'Belize (BZ)' },
  { code: 112, label: 'Bělorusko (BY)' },
  { code: 204, label: 'Benin (BJ)' },
  { code: 60, label: 'Bermudy (BM)' },
  { code: 64, label: 'Bhútán (BT)' },
  { code: 68, label: 'Bolívie (BO)' },
  { code: 535, label: 'Bonaire, Svatý Eustach a Saba (BQ)' },
  { code: 70, label: 'Bosna a Hercegovina (BA)' },
  { code: 72, label: 'Botswana (BW)' },
  { code: 74, label: 'Bouvetův ostrov (BV)' },
  { code: 76, label: 'Brazílie (BR)' },
  { code: 86, label: 'Britské indickooceánské území (IO)' },
  { code: 92, label: 'Britské Panenské ostrovy (VG)' },
  { code: 96, label: 'Brunej (BN)' },
  { code: 100, label: 'Bulharsko (BG)' },
  { code: 854, label: 'Burkina Faso (BF)' },
  { code: 108, label: 'Burundi (BI)' },
  { code: 184, label: 'Cookovy ostrovy (CK)' },
  { code: 531, label: 'Curaçao (CW)' },
  { code: 148, label: 'Čad (TD)' },
  { code: 499, label: 'Černá Hora (ME)' },
  { code: 156, label: 'Čína (CN)' },
  { code: 208, label: 'Dánsko (DK)' },
  { code: 180, label: 'Konžská demokratická republika (CD)' },
  { code: 212, label: 'Dominika (DM)' },
  { code: 214, label: 'Dominikánská republika (DO)' },
  { code: 262, label: 'Džibutsko (DJ)' },
  { code: 818, label: 'Egypt (EG)' },
  { code: 218, label: 'Ekvádor (EC)' },
  { code: 232, label: 'Eritrea (ER)' },
  { code: 233, label: 'Estonsko (EE)' },
  { code: 231, label: 'Etiopie (ET)' },
  { code: 234, label: 'Faerské ostrovy (FO)' },
  { code: 238, label: 'Falklandy (Malvíny) (FK)' },
  { code: 242, label: 'Fidži (FJ)' },
  { code: 608, label: 'Filipíny (PH)' },
  { code: 246, label: 'Finsko (FI)' },
  { code: 250, label: 'Francie (FR)' },
  { code: 254, label: 'Francouzská Guyana (GF)' },
  { code: 260, label: 'Francouzská jižní a antarktická území (TF)' },
  { code: 258, label: 'Francouzská Polynésie (PF)' },
  { code: 266, label: 'Gabon (GA)' },
  { code: 270, label: 'Gambie (GM)' },
  { code: 288, label: 'Ghana (GH)' },
  { code: 292, label: 'Gibraltar (GI)' },
  { code: 308, label: 'Grenada (GD)' },
  { code: 304, label: 'Grónsko (GL)' },
  { code: 268, label: 'Gruzie (GE)' },
  { code: 312, label: 'Guadeloupe (GP)' },
  { code: 316, label: 'Guam (GU)' },
  { code: 320, label: 'Guatemala (GT)' },
  { code: 831, label: 'Guernsey (GG)' },
  { code: 324, label: 'Guinea (GN)' },
  { code: 624, label: 'Guinea-Bissau (GW)' },
  { code: 328, label: 'Guyana (GY)' },
  { code: 332, label: 'Haiti (HT)' },
  { code: 334, label: 'Heardův ostrov a MacDonaldovy ostrovy (HM)' },
  { code: 340, label: 'Honduras (HN)' },
  { code: 344, label: 'Hongkong (HK)' },
  { code: 152, label: 'Chile (CL)' },
  { code: 191, label: 'Chorvatsko (HR)' },
  { code: 356, label: 'Indie (IN)' },
  { code: 360, label: 'Indonésie (ID)' },
  { code: 368, label: 'Irák (IQ)' },
  { code: 364, label: 'Írán (IR)' },
  { code: 372, label: 'Irsko (IE)' },
  { code: 352, label: 'Island (IS)' },
  { code: 380, label: 'Itálie (IT)' },
  { code: 376, label: 'Izrael (IL)' },
  { code: 388, label: 'Jamajka (JM)' },
  { code: 392, label: 'Japonsko (JP)' },
  { code: 887, label: 'Jemen (YE)' },
  { code: 832, label: 'Jersey (JE)' },
  { code: 710, label: 'Jižní Afrika (ZA)' },
  { code: 239, label: 'Jižní Georgie a Jižní Sandwichovy ostrovy (GS)' },
  { code: 728, label: 'Jižní Súdán (SS)' },
  { code: 400, label: 'Jordánsko (JO)' },
  { code: 136, label: 'Kajmanské ostrovy (KY)' },
  { code: 116, label: 'Kambodža (KH)' },
  { code: 120, label: 'Kamerun (CM)' },
  { code: 124, label: 'Kanada (CA)' },
  { code: 132, label: 'Kapverdy (CV)' },
  { code: 634, label: 'Katar (QA)' },
  { code: 398, label: 'Kazachstán (KZ)' },
  { code: 404, label: 'Keňa (KE)' },
  { code: 296, label: 'Kiribati (KI)' },
  { code: 166, label: 'Kokosové (Keelingovy) ostrovy (CC)' },
  { code: 170, label: 'Kolumbie (CO)' },
  { code: 174, label: 'Komory (KM)' },
  { code: 178, label: 'Konžská republika (CG)' },
  { code: 408, label: 'Korejská lidově demokratická republika (KP)' },
  { code: 410, label: 'Korejská republika (KR)' },
  { code: 95, label: 'Kosovo (XK)' },
  { code: 188, label: 'Kostarika (CR)' },
  { code: 192, label: 'Kuba (CU)' },
  { code: 414, label: 'Kuvajt (KW)' },
  { code: 196, label: 'Kypr (CY)' },
  { code: 417, label: 'Kyrgyzstán (KG)' },
  { code: 418, label: 'Laos (LA)' },
  { code: 426, label: 'Lesotho (LS)' },
  { code: 422, label: 'Libanon (LB)' },
  { code: 430, label: 'Libérie (LR)' },
  { code: 434, label: 'Libye (LY)' },
  { code: 438, label: 'Lichtenštejnsko (LI)' },
  { code: 440, label: 'Litva (LT)' },
  { code: 428, label: 'Lotyšsko (LV)' },
  { code: 442, label: 'Lucembursko (LU)' },
  { code: 446, label: 'Macao (MO)' },
  { code: 450, label: 'Madagaskar (MG)' },
  { code: 348, label: 'Maďarsko (HU)' },
  { code: 807, label: 'Severní Makedonie (MK)' },
  { code: 458, label: 'Malajsie (MY)' },
  { code: 454, label: 'Malawi (MW)' },
  { code: 462, label: 'Maledivy (MV)' },
  { code: 466, label: 'Mali (ML)' },
  { code: 470, label: 'Malta (MT)' },
  { code: 833, label: 'Man (IM)' },
  { code: 504, label: 'Maroko (MA)' },
  { code: 584, label: 'Marshallovy ostrovy (MH)' },
  { code: 474, label: 'Martinik (MQ)' },
  { code: 480, label: 'Mauricius (MU)' },
  { code: 478, label: 'Mauritánie (MR)' },
  { code: 175, label: 'Mayotte (YT)' },
  { code: 581, label: 'Menší odlehlé ostrovy USA (UM)' },
  { code: 484, label: 'Mexiko (MX)' },
  { code: 583, label: 'Mikronésie (FM)' },
  { code: 498, label: 'Moldavsko (MD)' },
  { code: 492, label: 'Monako (MC)' },
  { code: 496, label: 'Mongolsko (MN)' },
  { code: 500, label: 'Montserrat (MS)' },
  { code: 508, label: 'Mosambik (MZ)' },
  { code: 104, label: 'Myanmar (MM)' },
  { code: 516, label: 'Namibie (NA)' },
  { code: 520, label: 'Nauru (NR)' },
  { code: 276, label: 'Německo (DE)' },
  { code: 524, label: 'Nepál (NP)' },
  { code: 562, label: 'Niger (NE)' },
  { code: 566, label: 'Nigérie (NG)' },
  { code: 558, label: 'Nikaragua (NI)' },
  { code: 570, label: 'Niue (NU)' },
  { code: 528, label: 'Nizozemsko (NL)' },
  { code: 574, label: 'Norfolk (NF)' },
  { code: 578, label: 'Norsko (NO)' },
  { code: 540, label: 'Nová Kaledonie (NC)' },
  { code: 554, label: 'Nový Zéland (NZ)' },
  { code: 512, label: 'Omán (OM)' },
  { code: 586, label: 'Pákistán (PK)' },
  { code: 585, label: 'Palau (PW)' },
  { code: 275, label: 'Palestina (PS)' },
  { code: 591, label: 'Panama (PA)' },
  { code: 598, label: 'Papua Nová Guinea (PG)' },
  { code: 600, label: 'Paraguay (PY)' },
  { code: 604, label: 'Peru (PE)' },
  { code: 612, label: 'Pitcairn (PN)' },
  { code: 384, label: 'Pobřeží slonoviny (CI)' },
  { code: 616, label: 'Polsko (PL)' },
  { code: 630, label: 'Portoriko (PR)' },
  { code: 620, label: 'Portugalsko (PT)' },
  { code: 40, label: 'Rakousko (AT)' },
  { code: 638, label: 'Réunion (RE)' },
  { code: 226, label: 'Rovníková Guinea (GQ)' },
  { code: 642, label: 'Rumunsko (RO)' },
  { code: 643, label: 'Rusko (RU)' },
  { code: 646, label: 'Rwanda (RW)' },
  { code: 300, label: 'Řecko (GR)' },
  { code: 666, label: 'Saint Pierre a Miquelon (PM)' },
  { code: 222, label: 'Salvador (SV)' },
  { code: 882, label: 'Samoa (WS)' },
  { code: 674, label: 'San Marino (SM)' },
  { code: 682, label: 'Saúdská Arábie (SA)' },
  { code: 686, label: 'Senegal (SN)' },
  { code: 580, label: 'Severní Mariany (MP)' },
  { code: 690, label: 'Seychely (SC)' },
  { code: 694, label: 'Sierra Leone (SL)' },
  { code: 702, label: 'Singapur (SG)' },
  { code: 703, label: 'Slovensko (SK)' },
  { code: 705, label: 'Slovinsko (SI)' },
  { code: 706, label: 'Somálsko (SO)' },
  { code: 784, label: 'Spojené arabské emiráty (AE)' },
  { code: 840, label: 'Spojené státy (US)' },
  { code: 688, label: 'Srbsko (RS)' },
  { code: 140, label: 'Středoafrická republika (CF)' },
  { code: 729, label: 'Súdán (SD)' },
  { code: 740, label: 'Surinam (SR)' },
  { code: 654, label: 'Svatá Helena (SH)' },
  { code: 662, label: 'Svatá Lucie (LC)' },
  { code: 652, label: 'Svatý Bartoloměj (BL)' },
  { code: 659, label: 'Svatý Kryštof a Nevis (KN)' },
  { code: 663, label: 'Svatý Martin (FR) (MF)' },
  { code: 534, label: 'Svatý Martin (NL) (SX)' },
  { code: 678, label: 'Svatý Tomáš a Princův ostrov (ST)' },
  { code: 670, label: 'Svatý Vincenc a Grenadiny (VC)' },
  { code: 748, label: 'Svazijsko (SZ)' },
  { code: 760, label: 'Sýrie (SY)' },
  { code: 90, label: 'Šalomounovy ostrovy (SB)' },
  { code: 724, label: 'Španělsko (ES)' },
  { code: 744, label: 'Špicberky a Jan Mayen (SJ)' },
  { code: 144, label: 'Šrí Lanka (LK)' },
  { code: 752, label: 'Švédsko (SE)' },
  { code: 756, label: 'Švýcarsko (CH)' },
  { code: 762, label: 'Tádžikistán (TJ)' },
  { code: 834, label: 'Tanzanie (TZ)' },
  { code: 764, label: 'Thajsko (TH)' },
  { code: 158, label: 'Tchaj-wan (TW)' },
  { code: 768, label: 'Togo (TG)' },
  { code: 772, label: 'Tokelau (TK)' },
  { code: 776, label: 'Tonga (TO)' },
  { code: 780, label: 'Trinidad a Tobago (TT)' },
  { code: 788, label: 'Tunisko (TN)' },
  { code: 792, label: 'Turecko (TR)' },
  { code: 795, label: 'Turkmenistán (TM)' },
  { code: 796, label: 'Turks a Caicos (TC)' },
  { code: 798, label: 'Tuvalu (TV)' },
  { code: 800, label: 'Uganda (UG)' },
  { code: 804, label: 'Ukrajina (UA)' },
  { code: 858, label: 'Uruguay (UY)' },
  { code: 860, label: 'Uzbekistán (UZ)' },
  { code: 162, label: 'Vánoční ostrov (CX)' },
  { code: 548, label: 'Vanuatu (VU)' },
  { code: 336, label: 'Vatikán (VA)' },
  { code: 826, label: 'Velká Británie (GB)' },
  { code: 862, label: 'Venezuela (VE)' },
  { code: 704, label: 'Vietnam (VN)' },
  { code: 626, label: 'Východní Timor (TL)' },
  { code: 876, label: 'Wallis a Futuna (WF)' },
  { code: 894, label: 'Zambie (ZM)' },
  { code: 732, label: 'Západní Sahara (EH)' },
  { code: 716, label: 'Zimbabwe (ZW)' },
];
